import React from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';

const StyledListItem = styled.li`
    counter-increment: li;
    position: relative;
    font-size: ${({ theme }) => theme.fontSizes[4] + 'px'};
    margin-bottom: ${({ theme }) => theme.fontSizes[4] + 'px'};
    margin-left: ${({ theme }) => theme.fontSizes[6] + 'px'};

    &::before {
        display: inline-block;
        content: counter(li);
        color: ${({ theme, listColor }) => theme.colors[listColor || 'orange']};
        font-size: ${({ theme, listFontSize }) => theme.fontSizes[listFontSize || 7] + 'px'};
        font-family: ${({ theme, listFontFamily }) => theme.fonts[listFontFamily || 'title']};
        text-align: center;
        font-family: Arvo, sans-serif;
        vertical-align: middle;
        width: 1em;
        margin-left: -1em;
    }
`;

const StyledListItemText = styled.span`
    margin-left: ${({ theme }) => theme.fontSizes[5] + 'px'};
    display: inline-block;
    vertical-align: middle;
`;

export default styled(Box).attrs({
    as: 'ol',
    m: '0',
    mb: 4,
    color: 'grey'
})`
    list-style: none;
    display: block;
    counter-reset: li;
`;

export const NumberedListItem = ({ children }) => {
    return (
        <StyledListItem>
            <StyledListItemText>{children}</StyledListItemText>
        </StyledListItem>
    );
};