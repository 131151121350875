import React from 'react';
import styled from 'styled-components';
import { Flex, Heading, Text } from 'rebass';
import Img from 'gatsby-image';
import KitaExpertLogo from './Logo';
import { Measure, Lead, Container } from '../../utils/components';
import Cta from './Cta';
import { media } from '../../utils/theme';

const Tiler = styled.div`
  position: relative;
  overflow: hidden;
  background-attachment: none;
  margin: 0 auto;
  max-height: 700px;
`;

const Overlap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
`;

const AdaptiveBox = styled(Flex)`
  height: 100%;

  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  ${media.lessThan('md')`
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding-top: 16px;
    padding-bottom: 16px;
  `}
`;

const StyledImg = styled(Img)`
  max-height: 700px;
`;

const TallHeading = styled(Flex)`
  height: 100%;
  margin-top: 64px;
  ${media.lessThan('md')`
    margin-top: 32px;
    align-items: center;
  `};
`;

const StyledHeading = styled(Heading)`
  font-size: 72px;
  ${media.lessThan('md')`
    font-size: 40px;
  `};
`;

const StyledLead = styled(Lead)`
  margin-top: 128px;
  ${media.lessThan('md')`
    display: none;
  `};
`;

export default function Banner({ banner }) {
  return (
    <Tiler>
      <StyledImg fluid={banner.childImageSharp.fluid} />
      <Overlap>
        <Container height={'100%'}>
          <AdaptiveBox py={6}>
            <TallHeading flexDirection='column'>
              <StyledHeading>
                <KitaExpertLogo byMd={true} />
              </StyledHeading>
              <Measure>
                <StyledLead color='white' fontSize={5} fontWeight='normal'>
                  <Text>Finding a kita spot in Berlin is tough enough.</Text>
                  <Text mt={3}>Let us push you in the right direction</Text>
                </StyledLead>
              </Measure>
            </TallHeading>
            <Cta />
          </AdaptiveBox>
        </Container>
      </Overlap>
    </Tiler>
  );
}
