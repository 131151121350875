import React from 'react';
import Banner from '../components/Utils/Banner';
import { graphql } from 'gatsby';
import { Heading as BaseHeading, Text as BaseText } from 'rebass';
import { CenteredCta } from '../components/Utils/Cta';
import NumberedList, {
  NumberedListItem
} from '../components/Utils/NumberedList';
import styled from 'styled-components';
import { createPage } from '../template';
import { Section, Content } from '../utils/components';

const Heading = styled(BaseHeading).attrs({
  fontSize: 6,
  mb: 5,
  textAlign: 'center'
})`
  padding: 0 15px;
`;

const Text = styled(BaseText).attrs({
  as: 'p',
  color: 'grey',
  fontSize: 4,
  lineHeight: '1.5'
})``;

export default createPage(({ data }) => (
  <div>
    <Banner banner={data.banner} />
    <Section>
      <Content>
        <Heading color='orange'>How do I find a Kita spot?</Heading>
        <NumberedList>
          <NumberedListItem>
            Find kitas near you using this site and create a spreadsheet to help
            you coordinate your search
          </NumberedListItem>

          <NumberedListItem>
            Contact the kitas and ask when you can visit. Keep track of your
            contacts and dates in your spreadsheet
          </NumberedListItem>

          <NumberedListItem>
            Visit the kitas and apply for a spot for your child. You are now on
            the waiting list
          </NumberedListItem>

          <NumberedListItem>
            To stay on the waiting list you should contact the kitas monthly
            saying you’re still interested in the spot. Some kitas may not
            require this but they’ll instruct you otherwise in that case.{' '}
            <strong>Remember to update your spreadsheet.</strong>
          </NumberedListItem>
        </NumberedList>
        <CenteredCta />
      </Content>
    </Section>

    <Section bg='yellow'>
      <Content>
        <Heading color='grey'>What about the Kitagutschein?</Heading>
        <Text>
          {' '}
          A Kitagutschein is valid for 6 months. You can apply for kita spots
          without having a valid gutshein; and since queues can be 2 years long
          it’s quite common to do so. When you apply for a gutschein it can be
          as fast as one week for you to receive it.
        </Text>
      </Content>
    </Section>

    <Section bg='white'>
      <Content>
        <Heading color='orange'>
          This is too much. <br /> Can someone help me?
        </Heading>
        <Text mb={4}>
          Some Jugendamts assist parents in finding a spot. However, because of
          the current situation most of them stopped this service.
        </Text>
        <Text mb={4}>
          There are <strong>Kita Consultants</strong> who basically will do the
          above process for you for a fee. We don’t list kita consultants
          because we can’t verify their services. You may find them in relevant
          facebook groups offering their services.
        </Text>
        <Text mb={4}>
          We feel your pain - don’t panic. You’re in the same boat as thousands
          of other parents. The best we can do is recommend you either hire
          someone to do this for you; or you grind it out using the process
          above.
        </Text>
        <CenteredCta />
      </Content>
    </Section>
  </div>
));

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "baby_thumbsup.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`;
